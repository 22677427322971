@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.disabled-btn {
  pointer-events: none;
}

::v-deep .col-actions {
  width: 15%;
  text-align: right !important;
}

::v-deep .col-enabled {
  width: 10%;
}

::v-deep .col-owner {
  width: 10%;
}
